<template>
  <!-- eslint-disable -->
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      fill="#E2E5E7"
      d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
    />
    <path fill="#B0B7BD" d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z" />
    <polygon fill="#CAD1D8" points="480,224 384,128 480,128 " />
    <path
      fill="#84BD5A"
      d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z"
    />
    <g>
      <path
        fill="#FFFFFF"
        d="M193.1,315.2c2.9-24.8,40.4-29.3,58.1-15.7c8.7,7-0.5,18.2-8.2,12.5c-9.5-6-31-8.8-33.6,4.5
		c-3.5,21,52.2,9,51.3,43c-0.9,32.5-48,33.3-65.6,18.7c-4.2-3.4-4.1-9.1-1.8-12.5c3.3-3.3,7-4.5,11.4-0.9
		c10.5,7.2,37.5,12.5,39.4-5.6C242.4,340.2,189.2,351.6,193.1,315.2z"
      />
      <path
        fill="#FFFFFF"
        d="M305.4,378l-33.8-70.8c-5-10.1,10.1-18.4,15.7-7.8l11.4,25.7l14.7,33.8l14.4-33.8l11.4-25.7
		c5.1-9.7,20-3.6,15.6,7L322.3,378C319.4,386.1,310.3,388,305.4,378z"
      />
      <path
        fill="#FFFFFF"
        d="M92.3,339.7c0-24.7,15.5-45.9,44.9-45.9c11.1,0,20,3.3,29.3,11.4c3.5,3.2,3.8,8.8,0.4,12.4
		c-3.5,3.1-8.7,2.7-11.8-0.4c-5.2-5.5-10.6-7-17.9-7c-19.7,0-29.2,14-29.2,29.6c0,15.9,9.3,30.4,29.2,30.4c7.3,0,14.1-3,20-8.2
		c4-3.1,9.5-1.6,11.8,1.5c2,2.8,3.1,7.6-1.4,12c-9,8.3-19.7,10-30.3,10C106.2,385.5,92.3,364.4,92.3,339.7z"
      />
    </g>
    <path fill="#B0B7BD" d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z" />
  </svg>
  <!-- eslint-enable -->
</template>
