
import { Options, Vue } from 'vue-class-component';

import NoType from '@/icons/file-type/default.vue';

import doc from '@/icons/file-type/doc.vue';
import pdf from '@/icons/file-type/pdf.vue';
import xml from '@/icons/file-type/xml.vue';
import xls from '@/icons/file-type/xls.vue';
import xlsx from '@/icons/file-type/xlsx.vue';
import csv from '@/icons/file-type/csv.vue';

@Options({
  components: {
    doc,
    pdf,
    xml,
    xls,
    xlsx,
    csv,
    NoType,
  },
  props: {
    type: String,
  },
})
export default class IconFileType extends Vue {
  declare $props: { type: string; }

  public notDefaultType = false;

  created(): void {
    const components = { ...this.$options.components };
    const types = Object.keys(components).filter((v) => v !== 'NoType');

    this.notDefaultType = types.some((v) => v === this.$props.type);
  }
}
