import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pagination = _resolveComponent("pagination", true)!

  return (_ctx.$props.records && _ctx.$props.perPage)
    ? (_openBlock(), _createBlock(_component_pagination, {
        key: 0,
        modelValue: _ctx.page,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
        records: _ctx.$props.records,
        options: _ctx.options,
        "per-page": _ctx.$props.perPage,
        onPaginate: _ctx.paginate
      }, null, 8, ["modelValue", "records", "options", "per-page", "onPaginate"]))
    : _createCommentVNode("", true)
}