
import { Options, Vue } from 'vue-class-component';
import { watchEffect, WatchStopHandle } from 'vue';
import { BalanceResult, BALANCE_STATUS } from '@/interface/other.interface';

@Options({
  components: {

  },
  props: {
    total: Number,
    value: Number,
  },
  emits: {
    balance: Object,
  },
})
export default class Balance extends Vue {
  declare $props: {
    total: number;
    value: number;
  }

  public statuses = { value: BALANCE_STATUS.used, total: BALANCE_STATUS.prepaid };

  public values = { value: 0, total: 0 };

  /**
   * @var value - min: 0, max: 100;
   * @var total - min: 0, max: 100;
   */
  public percents = { value: 0, total: 100 };

  private watchStopHandle!: WatchStopHandle;

  public balance(): void {
    const value = JSON.parse(JSON.stringify(this.$props.value));

    const total = JSON.parse(JSON.stringify(this.$props.total));

    if (value < total) {
      const result = Number((value / (total / 100)).toFixed());

      this.percents.value = result;
      this.percents.total = 100 - result;

      this.values.value = value;
      this.values.total = total;
    } else if (value === total) {
      this.percents.value = 100;
      this.percents.total = 0;

      this.values.value = value;
      this.values.total = total;
    } else {
      const result = Number((total / (value / 100)).toFixed());
      const diff = value - total;

      this.percents.value = result;
      this.percents.total = 100 - result;

      this.values.value = total;
      this.values.total = diff > 0 && diff < 1 ? Number(diff.toFixed(2)) : diff;

      this.statuses.value = BALANCE_STATUS.prepaid;
      this.statuses.total = BALANCE_STATUS.overused;
    }

    this.triggerEmit();
  }

  private triggerEmit(): void {
    const obj: BalanceResult = {
      statuses: this.statuses,
      values: this.values,
      percents: this.percents,
    };

    this.$emit('balance', obj);
  }

  created(): void {
    this.balance();

    this.watchStopHandle = watchEffect(this.balance);
  }

  unmounted(): void {
    this.watchStopHandle();
  }
}
