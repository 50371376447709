
import { Options, Vue } from 'vue-class-component';
import { watch, WatchStopHandle } from 'vue';

import IconFileType from '@/icons/FileType.vue';

@Options({
  components: {
    IconFileType,
  },
  props: {
    value: String,
  },
})
export default class JobFileName extends Vue {
  declare $props: { value: string };

  public name = '';

  public type = '';

  private watchStopHandle!: WatchStopHandle;

  private parserFileName(): void {
    const type = this.$props.value.split('.');
    const name = [...type];

    name.pop();

    this.type = type[type.length - 1];
    this.name = name.join('.');
  }

  created(): void {
    this.parserFileName();

    this.watchStopHandle = watch(() => this.$props.value, this.parserFileName);
  }
}
