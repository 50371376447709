import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "balance" }
const _hoisted_2 = { class: "f-w-b" }
const _hoisted_3 = { class: "f-w-b" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["balance__stripe", _ctx.statuses.value]),
      style: _normalizeStyle({ width: `${_ctx.percents.value}%` })
    }, [
      (!_ctx.$slots.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("p", _hoisted_2, "$" + _toDisplayString(_ctx.values.value), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.statuses.value), 1)
          ], 64))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "value")
    ], 6),
    _createElementVNode("div", {
      class: _normalizeClass(["balance__stripe", _ctx.statuses.total]),
      style: _normalizeStyle({ width: `${_ctx.percents.total}%` })
    }, [
      (!_ctx.$slots.total)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("p", _hoisted_3, "$" + _toDisplayString(_ctx.values.total.toFixed(2)), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.statuses.total), 1)
          ], 64))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "total")
    ], 6)
  ]))
}