import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "accordion" }
const _hoisted_2 = { class: "accordion__header--title" }
const _hoisted_3 = {
  key: 0,
  class: "accordion__header--arrow m-left-30"
}
const _hoisted_4 = { class: "accordion__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconSmallArrow = _resolveComponent("IconSmallArrow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      role: "button",
      class: _normalizeClass(["accordion__header", { 'no-body': !_ctx.$slots.body }]),
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleShowAccordion && _ctx.toggleShowAccordion(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "header")
      ]),
      (_ctx.$slots.body)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_IconSmallArrow, {
              direction: _ctx.isOpen ? 'bottom' : 'right'
            }, null, 8, ["direction"])
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "body")
    ], 512), [
      [_vShow, _ctx.isOpen]
    ])
  ]))
}