
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import IconSmallArrow from '@/icons/service/SmallArrow.vue';

@Options({
  components: {
    IconSmallArrow,
  },
  props: {
    open: Boolean,
    singleOpen: Boolean,
  },
  computed: {
    ...mapState(['accordionId']),
  },
  emits: {
    status: String,
  },
})
export default class Accordion extends Vue {
  declare $props: {
    open: boolean;
    singleOpen: boolean;
  }

  declare $slots: {
    header: any;
    body: any;
  }

  public id = Math.random().toString(36).substr(2, 9); // generate unique id

  public isOpen = this.$props.open;

  public toggleShowAccordion(): void {
    if (this.$slots.body) {
      this.isOpen = !this.isOpen;

      this.$emit('status', this.isOpen ? 'open' : 'close');
    }
  }
}
