
import { Options, Vue } from 'vue-class-component';

import IconSmallArrow from '@/icons/service/SmallArrow.vue';

@Options({
  components: {
    IconSmallArrow,
  },
  props: ['props'],
})
export default class TemplatePagination extends Vue {
  declare $props: {
    props: any;
  };
}
