import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "file-name__block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconFileType = _resolveComponent("IconFileType")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_IconFileType, { type: _ctx.type }, null, 8, ["type"]),
    _createElementVNode("p", null, [
      _createElementVNode("b", null, _toDisplayString(_ctx.name), 1)
    ])
  ]))
}